import { rotateAround } from '../Arrays';

// Polyfill for el.matches(selector)
export const matches = (el, selector) => {
  if (!el) {
    return false;
  }
  if (el.matches) {
    return el.matches(selector);
  }
  //@ts-expect-error migrated from UIComponents
  if (el.msMatchesSelector) {
    //@ts-expect-error migrated from UIComponents
    return el.msMatchesSelector(selector);
  }
  if (el.parentElement) {
    const matchingSiblings = el.parentElement.querySelectorAll(selector);
    for (let i = 0; i < matchingSiblings.length; i++) {
      if (matchingSiblings[i] === el) {
        return true;
      }
    }
  }
  return false;
};
export const FOCUSABLE = `
  a[href]:not([tabindex="-1"]),
  button:enabled:not([tabindex="-1"]),
  input:enabled:not([tabindex="-1"]),
  textarea:enabled:not([tabindex="-1"]),
  select:enabled:not([tabindex="-1"]),
  [tabindex]:not(:disabled):not([tabindex="-1"]),
  [contenteditable]:not(:disabled):not([tabindex="-1"])
`;

// Polyfill for el.closest(selector)
export const closest = (el, selector) => {
  if (!el || !selector) {
    return null;
  }
  if (el.closest) {
    return el.closest(selector);
  }
  let currentEl = el;
  do {
    if (matches(currentEl, selector)) {
      return currentEl;
    }
    currentEl = currentEl.parentElement;
  } while (currentEl);
  return null;
};
export const moveFocusToNext = (selector, container, reverse = false) => {
  const allMatches = Array.from(container.querySelectorAll(selector));
  if (allMatches.length === 0) return null;

  // Get an ordered list of matching elements don't already contain the focus
  const currentMenuItem = closest(document.activeElement, selector);
  let candidateMatches = rotateAround(allMatches, currentMenuItem);
  candidateMatches = candidateMatches.filter(menuItem => !menuItem.contains(document.activeElement));

  // If reverse is set, flip the order so we move backward in the DOM
  if (reverse) candidateMatches.reverse();

  // Move the focus to the first focusable element found within a candidate element
  for (let i = 0; i < candidateMatches.length; i++) {
    const focusTarget = candidateMatches[i].querySelector(FOCUSABLE);
    if (focusTarget) {
      focusTarget.focus();
      return focusTarget;
    }
  }
  return null;
};
/**
 * These mappings were originally ported over from Tag but it's still unclear whether it should be considered the "source of truth"
 */
export const colorFamilyToTokenBackgroundColor = {
  calypso: 'fill-accent-blue-subtle',
  'candy-apple': 'fill-accent-red-subtle',
  heffalump: 'fill-accent-neutral-default',
  koala: 'fill-tertiary-default',
  lorax: 'fill-accent-orange-subtle',
  marigold: 'fill-accent-yellow-subtle',
  norman: 'fill-accent-magenta-subtle',
  olaf: 'icon-primary-default',
  oz: 'fill-accent-green-subtle',
  pantera: 'fill-tertiary-alt',
  sorbet: 'fill-accent-light-orange-subtle',
  thunderdome: 'fill-accent-purple-subtle'
};

/**
 * These mappings were originally ported over from Tag but it's still unclear whether it should be considered the "source of truth"
 */
export const colorFamilyToTokenBorderColor = {
  calypso: 'fill-accent-blue-default',
  'candy-apple': 'fill-accent-red-default',
  heffalump: 'fill-accent-neutral-default',
  koala: 'fill-tertiary-default',
  lorax: 'fill-accent-orange-default',
  marigold: 'fill-accent-yellow-default',
  norman: 'fill-accent-magenta-default',
  olaf: 'icon-primary-default',
  oz: 'fill-accent-green-default',
  pantera: 'fill-accent-neutral-default',
  sorbet: 'fill-accent-light-orange-default',
  thunderdome: 'fill-accent-purple-default'
};